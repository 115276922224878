import { onBeforeUnmount, ref } from 'vue'
import useUtils from './useUtils'
import locoScroll from '@/assets/js/LenisScroll'

const observer = ref()

export default function useSmoothScroll() {
  const { setScrollProgress } = useUtils()

  locoScroll.value.on('scroll', ({ progress }) => {
    setScrollProgress(progress)
  })
  function raf(time) {
    locoScroll.value.raf(time)
    requestAnimationFrame(raf)
  }
  requestAnimationFrame(raf)
  observer.value = new ResizeObserver(() => refreshScroll()).observe(
    document.querySelector('body')
  )

  onBeforeUnmount(() => {
    // console.log(observer.value)
    if (observer.value) {
      observer.value.disconnect()
    }
  })

  const refreshScroll = () => {
    // use it if need it
  }

  return {
    locoScroll,
    observer,
  }
}
