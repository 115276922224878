<template>
  <li
    v-if="item.parent === '0'"
    class="menu-items group relative items-center"
    :class="`${hasSub(item)} ${
      device === 'mobile' ? 'mobile-subnav-root' : ''
    }`">
    <template v-if="item.class.includes('has-subnav')">
      <template v-if="item.url !== '#'">
        <template v-if="device === 'mobile'">
          <div class="z-2 inline-block relative">
            <router-link
              class="mobile-subnav-parent lg:mb-0"
              :class="subnavOpen ? 'mb-3' : ''"
              :to="convertToRelative(item.url)"
              >{{ item.content }}</router-link
            >
          </div>
          <div
            class="submenu-trigger z-1 top-2 lg:top-0 -right-0 lg:-right-10 absolute h-[37px] w-full"
            @click="openSubnav"></div>
        </template>
        <template v-else>
          <router-link
            class="desktop-subnav-parent mb-3 lg:mb-0"
            :to="convertToRelative(item.url)">
            {{ item.content }}
          </router-link>
        </template>
      </template>
      <template v-else>
        <div class="desktop-subnav-parent mb-0 lg:mb-3">
          {{ item.content }}
          <div
            class="ml-2 transform group-hover:rotate-180 transition-transform ease-in-out flex items-center chevron"
            :class="{ 'rotate-180': subnavOpen }">
            <svg
              width="13px"
              height="6px"
              viewBox="0 0 13 6"
              version="1.1"
              stroke="inherit">
              <g
                stroke="inherit"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="0 0">
                <g
                  transform="translate(-1074.000000, -93.000000)"
                  stroke="inherit">
                  <g transform="translate(711.000000, 86.000000)">
                    <g transform="translate(304.000000, 0.000000)">
                      <path d="M60,7 L65.5,13 L71,7" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <template v-if="device === 'mobile' || device === 'tablet'">
        <Vue3SlideUpDown
          v-model="subnavOpen"
          class="subnav-accordion">
          <ul class="sub-menu">
            <template v-for="sub in menu">
              <li
                v-if="sub.parent == item.id"
                :key="sub.id"
                :title="sub.title">
                <template v-if="isExternal(sub.url)">
                  <a
                    :href="sub.url"
                    :target="sub.target"
                    :title="sub.content"
                    @click="closeNav"
                    >{{ sub.content }}</a
                  >
                </template>
                <template v-else>
                  <router-link
                    :to="convertToRelative(sub.url)"
                    @click="closeNav"
                    >{{ sub.content }}</router-link
                  >
                </template>
              </li>
            </template>
          </ul>
        </Vue3SlideUpDown>
      </template>
      <template v-else>
        <ul class="sub-menu">
          <template v-for="sub in menu">
            <li
              v-if="sub.parent == item.id"
              :key="sub.id"
              :title="sub.title"
              :class="arrayToString(sub.class)">
              <template v-if="isExternal(sub.url)">
                <a
                  :href="sub.url"
                  :class="arrayToString(sub.class)"
                  :target="sub.target"
                  :title="sub.content"
                  >{{ sub.content }}</a
                >
              </template>
              <template v-else>
                <router-link
                  :to="convertToRelative(sub.url)"
                  @click="closeNav"
                  >{{ sub.content }}</router-link
                >
              </template>
            </li>
            <template v-else></template>
          </template>
          <div class="triangle"></div>
        </ul>
      </template>
    </template>
    <template v-else-if="item.class.includes('CTA') && !device === 'mobile'">
      <router-link
        :to="convertToRelative(item.url)"
        exact
        @click="closeNav">
        {{ item.content }}
        <svg
          class="ml-2"
          width="17"
          height="8"
          viewBox="0 0 17 8"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.844 4.18558C17.0393 3.99032 17.0393 3.67374 16.844 3.47848L13.6621 0.296497C13.4668 0.101235 13.1502 0.101235 12.955 0.296497C12.7597 0.491759 12.7597 0.808342 12.955 1.0036L15.7834 3.83203L12.955 6.66046C12.7597 6.85572 12.7597 7.1723 12.955 7.36757C13.1502 7.56283 13.4668 7.56283 13.6621 7.36757L16.844 4.18558ZM0.925781 4.33203H16.4905V3.33203H0.925781V4.33203Z" />
        </svg>
      </router-link>
    </template>
    <template v-else>
      <router-link
        :to="convertToRelative(item.url)"
        exact
        @click="closeNav"
        v-html="item.content">
      </router-link>
    </template>
  </li>
</template>

<script setup>
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import useUtils from '@/components/composables/useUtils.js'
import { ref, watch } from 'vue'

const { device, scrollY, convertToRelative, isExternal } = useUtils()
defineProps(['menu', 'item'])
const subnavOpen = ref()
const sticky = ref()

watch(scrollY, (newVal) => {
  if (newVal > 50) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

const hasSub = (item) => {
  return item.class.includes('has-subnav')
    ? `parent-nav ${arrayToString(item.class)}`
    : `${arrayToString(item.class)}`
}
const openSubnav = () => {
  subnavOpen.value = !subnavOpen.value
}

const closeNav = () => {
  subnavOpen.value = false
}

const arrayToString = (array) => {
  return array.join(' ')
}
</script>

<style lang="scss"></style>
