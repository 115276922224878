import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from '../scripts/emitter'
import { useStore } from '@/stores/main'
import locoScroll from '../assets/js/LenisScroll'

const { url } = window.__VUE_WORDPRESS__.routing
// scroll position is handled in @after-leave transition hook
if ('scrollRestoration' in window.history)
  window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const position = { left: 0, top: 0 }
    // Only way I could see to go to an anchor on another page. Possible race condition.
    if (to.hash) {
      const navigate = () => {
        const store = useStore()
        if (store.site.loading) {
          setTimeout(() => {
            navigate()
          }, 500)
        } else {
          setTimeout(() => {
            locoScroll.value.scrollTo(to.hash, {
              duration: 1,
              offset: -150,
            })
          }, 500)
        }
      }

      navigate()
    }

    if (savedPosition) {
      position.x = savedPosition.x
      position.y = savedPosition.y

      return new Promise((resolve) => {
        emitter.on('scrollBeforeEnter', (done) => {
          // resolve(position)
          resolve(emitter.emit('scrollToTop'))
          done()
        })
      })
    } else {
      return new Promise((resolve) => {
        emitter.on('scrollBeforeEnter', (done) => {
          // resolve(position)
          resolve(emitter.emit('scrollToTop'))
          done()
        })
      })
    }
  },
})
