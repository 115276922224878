<template>
  <header
    id="header-nav"
    ref="header"
    class="header-nav"
    :class="`${navOpen ? 'nav-open' : ''}`">
    <nav ref="navWrapper">
      <div class="logo">
        <a href="/">
          <img
            :src="site.site_logo.url"
            alt="Intrinsic logo" />
        </a>
      </div>
      <div
        class="nav-wrapper"
        :class="`${navOpen ? 'nav-open' : ''} `"
        data-lenis-prevent>
        <ul
          v-if="device === 'desktop'"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in topLevelMenu(menu)"
              :key="item.id"
              appear
              @before-enter="beforeEnter"
              @enter="enter">
              <template v-if="item.parent === '0'">
                <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :menu="menu"
                  :item="item"
                  @click="close" />
              </template>
            </transition-group>
          </template>
        </ul>
      </div>
      <a
        :href="site.header_cta.url"
        class="header-cta">
        <div>
          <p>
            {{ site.header_cta.title }}
          </p>
        </div>
      </a>
      <div
        v-if="device !== 'desktop'"
        class="hamburger"
        :class="`${navOpen ? 'nav-open' : ''}`"
        @click="open">
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </div>
    </nav>
    <div class="gradient-line"></div>
    <div
      v-if="device !== 'desktop'"
      class="mobile-menu-container">
      <Vue3SlideUpDown v-model="navOpen">
        <ul class="mobile-menu nav-container">
          <transition-group
            v-for="(item, index) in topLevelMenu(menu)"
            :key="item.id"
            appear
            @before-enter="beforeEnter"
            @enter="enter">
            <template v-if="item.parent === '0'">
              <NavMenuItem
                :key="item.id"
                :data-ind="index"
                :menu="menu"
                :item="item"
                @click="close" />
            </template>
          </transition-group>
        </ul>
      </Vue3SlideUpDown>
    </div>
  </header>
</template>

<script setup>
import NavMenuItem from './NavMenuItem.vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ref, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import useIntroShown from '@/components/composables/useIntroShown.js'
import useSmoothScroll from '@/components/composables/useSmoothScroll.js'
import useUtils from '@/components/composables/useUtils.js'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref(null)
const navOpen = ref(false)
const anchorRetry = ref(0)

const { introShown } = useIntroShown()
const { device } = useUtils()
const { locoScroll } = useSmoothScroll()

const topLevelMenu = (menu) => {
  const topMenu = menu.filter((x) => x.parent === '0')
  return topMenu
}

const open = () => {
  if (navOpen.value) {
    navOpen.value = false
    locoScroll.value.start()
  } else {
    navOpen.value = true
    locoScroll.value.stop()
  }
}

const close = () => {
  navOpen.value = false
  locoScroll.value.start()
}

const beforeEnter = (el) => {
  // add the initial style to the elements
  el.style.transform = 'translateY(10px)'
  el.style.opacity = 0
}

const enter = (el) => {
  gsap.to(el, {
    y: 0,
    opacity: 1,
    duration: 0.3,
    delay: el.dataset.ind * 0.3,
  })
}

const setAnchors = () => {
  const menuItems = gsap.utils.toArray('.nav-container .menu-items')

  const backToDefault = (el) => {
    el.style.fontWeight = 400
    el.style.color = '#000'
  }

  const curated = []

  menuItems.forEach((el) => {
    const element = document.querySelector(`#${el.innerText}`)
    if (element) {
      curated.push({ menuItem: el, anchorElement: element })
    }
  })

  curated.forEach((el, idx) => {
    gsap.to(el, {
      scrollTrigger: {
        trigger: el.anchorElement,
        endTrigger:
          idx + 1 < curated.length
            ? curated[idx + 1].anchorElement
            : '.site-footer',
        id: `#${el.menuItem.innerText}`,
        start: 'top bottom',
        end: idx + 1 < curated.length ? 'bottom bottom' : 'bottom+=200 bottom',
        markers: false,
        onEnter: () => {
          el.menuItem.style.fontWeight = 500
          el.menuItem.style.color = '#A62BF4'
        },
        onEnterBack: () => {
          el.menuItem.style.fontWeight = 500
          el.menuItem.style.color = '#A62BF4'
        },
        onLeave: () => {
          backToDefault(el.menuItem)
        },
        onLeaveBack: () => {
          backToDefault(el.menuItem)
        },
      },
    })
  })
}

const show_hide_header = () => {
  const site_header = document.querySelector('#header-nav')

  const show_hide_header = gsap
    .from(site_header, {
      yPercent: -100,
      duration: 0.25,
      ease: 'sine.out',
    })
    .progress(1)

  ScrollTrigger.create({
    start: 'top top-=' + 100,
    onUpdate: (self) => {
      if (self.direction === -1) show_hide_header.play()
      else show_hide_header.reverse()
    },
  })
}

const checkForAnchors = () => {
  const menuItems = gsap.utils.toArray('.nav-container > li')
  if (
    !document.querySelector(`#${menuItems[0]?.innerText}`) &&
    anchorRetry.value < 10 &&
    device.value === 'desktop'
  ) {
    setTimeout(() => {
      console.log('retrying...')
      checkForAnchors()
      anchorRetry.value++
    }, 100)
  } else {
    setAnchors()
  }
}

onMounted(() => {
  checkForAnchors()
  show_hide_header()
})
</script>

<style lang="scss">
.header-nav {
  @apply h-[60px] lg:h-[90px] fixed z-20 w-full bg-white shadow-xl lg:shadow-none;
  nav {
    @apply flex items-center justify-between h-full w-full;
    .hamburger {
      @apply flex items-center justify-center w-12 h-12 relative mx-[30px];

      .line {
        position: absolute;
        height: 3px;
        width: 100%;
        background: #a62bf4;
        transition: 0.5s;
      }
      /* steps of 12 are optional, could also be 10, 13 etc */
      .line:nth-child(1) {
        top: 12px;
      }
      .line:nth-child(2) {
        top: 24px;
      }
      .line:nth-child(3) {
        top: 36px;
      }

      /* translateY must be same as */
      &.nav-open .line:nth-child(1) {
        transform: translateY(12px) rotate(-45deg);
      }

      &.hamburger.nav-open .line:nth-child(2) {
        opacity: 0;
      }

      &.hamburger.nav-open .line:nth-child(3) {
        transform: translateY(-12px) rotate(45deg);
      }
    }
    .logo {
      @apply w-48 lg:w-64 h-full flex items-center justify-center border-r-0 lg:border-r border-black px-[30px];
      img {
        @apply max-h-[60px];
      }
    }
    .nav-wrapper {
      @apply hidden lg:flex;
      .nav-container {
        @apply flex gap-x-9;
        li {
          @apply transition-all duration-300 ease-in-out text-[20px];
          &:hover {
            @apply text-primary;
          }
        }
      }
    }
    .header-cta {
      @apply hidden lg:flex w-64 h-full items-center justify-center border-l-0 lg:border-l border-black hover:bg-primary hover:text-white transition-colors duration-300 ease-in-out px-[30px];
    }
  }
  .gradient-line {
    @apply w-full h-[2px] bg-main-gradient;
  }

  .mobile-menu-container {
    @apply inset-0 z-10 bg-purple;
    .mobile-menu {
      @apply flex flex-col items-start h-[calc(100vh-60px)] gap-y-6 justify-start px-8 pt-10;
      li {
        @apply transition-all duration-300 ease-in-out list-none text-[40px] pb-2 text-white border-b border-primary w-full;
        &:hover {
          @apply text-primary;
        }
      }
    }
  }
}
</style>
