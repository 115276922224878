import Lenis from '@studio-freight/lenis'
import { ref } from 'vue'

const locoScroll = ref()

export const activate = () => {
  if (locoScroll.value) return
  locoScroll.value = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    orientation: 'vertical', // vertical, horizontal
    gestureOrientation: 'vertical', // vertical, horizontal, both
    smoothWheel: true,
    wheelMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
  })
}

activate()

export default locoScroll
