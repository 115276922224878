<template>
  <div class="footer-gradient"></div>
  <footer
    v-show="!store.site.loading && site"
    ref="trigger"
    class="site-footer">
    <div class="footer-container">
      <div class="footer-head">
        <div class="logo">
          <router-link
            :to="'/'"
            title="Home">
            <img
              ref="logo"
              :src="site.site_logo.url"
              alt="Intrinsic Logo" />
          </router-link>
          <div class="tagline">
            <p>{{ site.footer_tagline }}</p>
          </div>
        </div>
        <div
          v-if="device === 'desktop'"
          class="copyright">
          <p v-html="site.footer_copyright" />
        </div>
      </div>
      <div class="separator"></div>
      <div class="footer-menu">
        <footer-menu name="footer-menu" />
      </div>
      <div class="separator"></div>
      <div class="footer-contact">
        <p class="contact-title">Contact</p>
        <div
          class="contact-info"
          v-html="site.contact_info" />
        <div class="socials">
          <a
            v-if="site.twitter"
            :href="site.twitter"
            target="_blank"
            class="social twitter">
            <img
              src="@/assets/img/x.svg"
              alt="Twitter Logo" />
          </a>
          <a
            v-if="site.linkedin"
            :href="site.linkedin"
            target="_blank"
            class="social linkedin">
            <img
              src="@/assets/img/linkedin.svg"
              alt="LinkedIn Logo" />
          </a>
        </div>
        <div
          v-if="device !== 'desktop'"
          class="copyright">
          <p v-html="site.footer_copyright" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import FooterMenu from '@/components/template-parts/FooterMenu.vue'
import { useStore } from '@/stores/main'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useUtils from '@/components/composables/useUtils.js'

const { device } = useUtils()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const site = ref(store.acfOptions())

const trigger = ref(null)
const logo = ref(null)

let tl

// const currentYear = computed(() => {
//   return new Date().getFullYear()
// })

const refreshScrollPositions = () => {
  ScrollTrigger.refresh()
}

onMounted(() => {
  // document.addEventListener('scroll', refreshScrollPositions)
  setInterval(refreshScrollPositions, 5000)

  tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.value,
      start: 'top bottom',
      end: 'bottom-=150 bottom',
      scrub: true,
    },
  })

  tl.to(logo.value, {
    y: 0,
  })
})

onBeforeUnmount(() => {
  // document.removeEventListener('scroll', refreshScrollPositions)
  clearInterval(refreshScrollPositions)
  tl.kill()
})
</script>

<style lang="scss">
.footer-gradient {
  @apply bg-main-gradient w-full h-[2px];
}
.site-footer {
  @apply w-full min-h-[380px] flex items-stretch overflow-hidden;
  .footer-container {
    @apply module-container flex flex-col lg:flex-row;
    .separator {
      @apply flex lg:hidden w-[120vw] h-1 bg-black relative -left-12;
    }
    .footer-head {
      @apply w-full h-full flex flex-col justify-between pt-[25px] mb-10 border-r-0 border-black
      lg:w-1/2 lg:pt-14 lg:pb-8 lg:border-r;
      .logo {
        img {
          @apply w-auto h-20 mb-[57px] lg:mb-11;
        }
        .tagline {
          p {
            @apply text-[29px] opacity-50;
          }
        }
      }
      .copyright {
        p {
          @apply text-[14px];
          a {
            @apply underline;
          }
        }
      }
    }
    .footer-menu {
      @apply w-full lg:w-1/5 border-r-0 lg:border-r border-black pl-4 lg:pl-12  pt-6 lg:pt-14 pb-8;
      ul {
        li {
          @apply text-[22px] mb-4;
        }
      }
    }
    .footer-contact {
      @apply w-full lg:w-[30%] pl-4 lg:pl-12 pt-6 lg:pt-14 pb-8;
      .contact-title {
        @apply text-[20px] mb-10;
      }
      .contact-info {
        @apply mb-4 lg:mb-16;
        a {
          @apply underline text-primary;
        }
      }
      .socials {
        @apply flex flex-row gap-x-4 mb-10;
      }
      .copyright {
        p {
          @apply text-[16px] lg:text-[14px] flex flex-col sm:flex-row;
          a {
            @apply underline;
          }
        }
      }
    }
  }
}
</style>
