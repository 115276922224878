import { ref, onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import shortUUID from 'short-uuid'

const scrollY = ref(0)
const scrollX = ref(0)
const scrollProgress = ref(0)

export default function useUtils() {
  const device = ref()
  const observer = ref()

  const genUUID = () => {
    return shortUUID('0123456789ABCDEFGHIJKLMNOQRSTUVWXYZ').generate()
  }

  const decodeTitle = (title) => {
    const txt = document.createElement('textarea')
    txt.innerHTML = title
    return txt.value
  }

  const convertToRelative = (url) => {
    const router = useRouter()
    const store = useStore()
    const currentRoute = router.currentRoute.value.fullPath
    if(currentRoute === '/') {
      return url.replace(store.site.url, '')
    }
    else {
      return '/'+url
    }
  }

  const isExternal = (url) => {
    const domain = (url) => {
      return url.replace('http://', '').replace('https://', '').split('/')[0]
    }
    return domain(location.href) !== domain(url)
  }

  const isDevice = (val) => {
    if (val < 768) {
      device.value = 'mobile'
      return 'mobile'
    } else if (val >= 768 && val < 1024) {
      device.value = 'tablet'
      return 'tablet'
    } else if (val >= 1024) {
      device.value = 'desktop'
      return 'desktop'
    }
  }

  const setScrollProgress = (progress) => {
    scrollProgress.value = progress
  }

  onBeforeMount(() => {
    observer.value = new ResizeObserver((entries) => {
      for (const entry of entries) {
        device.value = isDevice(entry.contentRect.width)
      }
    })

    observer.value.observe(document.querySelector('body'))
    device.value = isDevice(window.screen.width)
  })

  onMounted(() => {
    const body = document.querySelector('body')
    body.addEventListener('scroll', () => {
      scrollY.value = body.scrollY
      scrollX.value = body.scrollX
    })
  })

  onBeforeUnmount(() =>
    observer.value.unobserve(document.querySelector('body'))
  )

  return {
    device,
    scrollY,
    scrollProgress,
    scroll,
    decodeTitle,
    convertToRelative,
    isExternal,
    setScrollProgress,
    genUUID,
  }
}
